(function () {
  "use strict";

  /**
   * Toggle the footer links to simulate accordions.
   *
   * @return {void}
   */
  const toggleFooterLinks = function (e) {
    const sibling = this.nextSibling.nextSibling;
    let footerNavs = Array.from(
      document.querySelectorAll(".ripple-footer__nav")
    );
    // Ensure that accordion functionality is only applied in mobile
    // and that users can still navigate using the link headings.
    // We also need to account for keyboard navigation.
    if (
      (window.innerWidth < 1024 &&
        !e.target.classList.contains("ripple-footer__link") &&
        e.type === "click") ||
      (e.type === "keydown" && e.key === "Enter")
    ) {
      // Remove any active classes from footer navs other than
      // the current one. Also convert NodeList to Array for IE11.
      if (footerNavs.length > 0) {
        footerNavs.forEach((el) => {
          if (sibling !== el) {
            el.classList.remove(activeNavClass);

            if (el.previousSibling.previousSibling instanceof HTMLElement) {
              el.previousSibling.previousSibling.classList.remove(
                activeHeadingClass
              );
            }
          }
        });
      }

      // Toggle activeNavClass based on switch
      switch (sibling.classList.contains(activeNavClass)) {
        case true:
          sibling.classList.remove(activeNavClass);
          this.classList.remove(activeHeadingClass);
          sibling.setAttribute("aria-hidden", true);
          sibling.setAttribute("aria-expanded", false);
          break;
        case false:
          sibling.classList.add(activeNavClass);
          this.classList.add(activeHeadingClass);
          sibling.setAttribute("aria-hidden", false);
          sibling.setAttribute("aria-expanded", true);
          break;
      }
    }
  };

  // Retrieve all heading elements and navs in the footer
  const footerHeadings = Array.from(
    document.querySelectorAll(".js-ripple-footer__heading")
  );

  const activeNavClass = "ripple-footer__nav--active";
  const activeHeadingClass = "ripple-footer__heading--active";

  // If there are footerheadings, then we'll bind click events
  if (footerHeadings.length > 0) {
    footerHeadings.forEach((el) => {
      el.addEventListener("click", toggleFooterLinks);
      el.addEventListener("keydown", toggleFooterLinks);
    });
  }

  // Fixing matrix bug.
  const removedDuplicateItem = {
    data: "data-id",
    init() {
      try {
        const elements = Array.from(
          document.querySelectorAll(`[${this.data}]`)
        );
        const duplicateItem = {};
        if (elements.length > 0) {
          elements.forEach((el) => {
            const key = el.getAttribute(this.data);
            duplicateItem[key] =
              typeof duplicateItem[key] === "undefined" ? false : true;
          });

          elements.forEach((el) => {
            const key = el.getAttribute(this.data);
            duplicateItem[key] &&
              el.tagName === "LI" &&
              el.parentNode.removeChild(el);
          });
        }
      } catch (e) {
        console.log(e);
      }
    },
  };

  removedDuplicateItem.init();
})();

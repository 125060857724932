/*global jQuery, visible*/
(function ($) {
    'use strict';

    var $sliderNav = $('.block-nav__list'),
        $blockNav  = $('.block-nav'),
        $slick,
        slideCount;

    if ($sliderNav.length) {

        $sliderNav
            .on('afterChange', function (event, slick, currentSlide) {

                // Hide $prevArrow when no LHS slides to show
                if (currentSlide === 0) {
                    slick.$prevArrow.hide();
                } else {
                    slick.$prevArrow.show();
                }

                // Hide $nextArrow when last slide is visible
                if (visible(slick.$slides[slick.slideCount - 1])) {
                    slick.$nextArrow.hide();
                } else {
                    slick.$nextArrow.show();
                }
            });

        $sliderNav.slick({
            accessibility:  false,
            slidesToScroll: 1,
            dots:           false,
            focusOnSelect:  false,
            centerMode:     false,
            infinite:       false,
            touchMove:      true,
            variableWidth:  true
        });

        
        $sliderNav.each(function () {
            $slick     = $(this).slick('getSlick');
            slideCount = $slick.slideCount;

            // Hide prev arrow after slick init.
            $slick.$prevArrow.hide();

            // Hide $nextArrow when last slide is visible
            if (visible($slick.$slides[$slick.slideCount - 1])) {
                $slick.$nextArrow.hide();
            } else {
                $slick.$nextArrow.show();
            }
        });
    }

    $blockNav.UberAccordion({
        buttonClass:          'block-nav__link',
        autoExpand:           function ($elem) {
            return window.location.hash || this.$buttons.first().attr('href');
        },
        toggle:               false,
        multiple:             false,
        preventDefaultButton: true
    });
}(jQuery));
/*global jQuery*/
/**
 * {{name}}
 * Global JS
 *
 * version: {{version}}
 * file:    {{file}}
 * author:  Squiz Australia
 * change log:
 *     {{date}} - First revision
 */

/*
 * Table of Contents
 *
 * - Global
 * - Modules
{{toc}}
 */
'use strict';
/*
--------------------
Global
--------------------
*/
//  Declare JS Enabled.
$('body').removeClass('no-js').addClass('js-enabled');


/*
--------------------
Modules
--------------------
*/

// Checks element visibility (based on single point in the right-center of that element)
// Checks that element is not underneath another element
function visible(element) {
    if(element) {

        var rects = element.getClientRects(),
            on_top = function (r) {
                var x = r.right,
                    y = (r.top + r.bottom) / 2;
                if (document.elementFromPoint(x, y) === element.children[0] || document.elementFromPoint(x, y) === element) { return true; }
            };

        for (var i = 0; i < rects.length; i++) {
            var r = rects[i];
            if (on_top(r)) { return true; }
        }
        return false;
    }
    else {
        return false;
    }
}
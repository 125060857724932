/*global jQuery*/
(function ($) {
    'use strict';

    var $lhsMenuTrigger = $('nav#lhs-nav button.lhs-nav__cta');

    $lhsMenuTrigger.each(function () {
        if ($(this).parents('.lhs-nav__link--current').length || $(this).parents('.lhs-nav__item--open').length) {
            $(this)
                .toggleClass('lhs-nav__cta--active')
                .children('span.sr-only').toggleText('Open submenu', 'Close submenu');
        }
    });

    $lhsMenuTrigger.on('click', function (e) {
        e.preventDefault();

        $(this)
            .siblings('ul.lhs-nav__sublist').slideToggle('fast')
            .end().toggleClass('lhs-nav__cta--active')
            .children('span.sr-only').toggleText('Open submenu', 'Close submenu');
    });

}(jQuery));

/*global jQuery*/
(function ($) {
    'use strict';

    var $footerGroup = $('div.footer__group'),
        uagent       = navigator.userAgent.toLowerCase();

	if (/safari/.test(uagent) && !/chrome/.test(uagent)) {
	    $footerGroup.matchHeight();
	}

}(jQuery));
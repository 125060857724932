(function($) {
    'use strict';

    var domain = window.location.hostname;
    var baseURL = '';

    // Simple ENV variable - can be removed on prod
    switch (domain) {
        case 'localhost':
            baseURL = 'http://www2.delwp.vic.gov.au';
            break;
        default:
            baseURL = '';
    }

    var FUNNELBACK_COLLECTION = 'delwp-meta';
    var GA_REST = baseURL + '/_webservices/google-analytics/google-analytics-data';
    var FUNNELBACK_REST = baseURL + '/_webservices/google-analytics/funnelback';


    var CHART_OPTIONS = {
        exporting: {
            chartOptions: {
                plotOptions: {
                    series: {
                        dataLabels: {
                        enabled: true
                    }
                }
            }
        },
        fallbackToExportServer: false
    }};


    const arrayColumn       = (arr, n) => arr.map(x => parseInt(x[n]));
    const arrayColumnString = (arr, n) => arr.map(x => x[n]);

    function capitalizeTxt(txt) {
      return txt.charAt(0).toUpperCase() + txt.slice(1);
    }



    var currentMonth = 1
    var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
                  'Jul', 'Aus', 'Sep', 'Oct', 'Nov', 'Dec'];

    function formatDate(str) {
        // 20170515
        return str.toString().substring(6,8) + ' ' + months[parseInt(str.toString().substring(4,6)) - 1];
    }

    function makeBarChart(params) {
        var url = GA_REST + '?ids=ga:' + params.ids + '&' + params.query;

        $.getJSON(url, function(data){

            var chart = Highcharts.chart(params.el, {
                CHART_OPTIONS,
                chart: {
                    type: 'bar'
                },
                title: {
                    text: params.title
                }
            });

            var headers = data.columnHeaders;

            var categories = arrayColumnString(data.rows,0);
            chart.xAxis[0].update({categories:categories}, true);

            for (var i = 1; i < data.rows[0].length; i++) {
                chart.addSeries({
                    name: formatSeriesName(headers[i].name),
                    data: arrayColumn(data.rows,i)
                });
            }

        });
    }


    function makeColumnChart(params) {
        var url = GA_REST + '?ids=ga:' + params.ids + '&' + params.query;


        $.getJSON(url, function(data){

            var chart = Highcharts.chart(params.el, {
                CHART_OPTIONS,
                chart: {
                    type: 'column'
                },
                title: {
                    text: params.title
                }
            });

            var headers = data.columnHeaders;

            var categories = arrayColumnString(data.rows,0);
            chart.xAxis[0].update({categories:categories}, true);

            for (var i = 1; i < data.rows[0].length; i++) {
                chart.addSeries({
                    name: formatSeriesName(headers[i].name),
                    data: arrayColumn(data.rows,i)
                });
            }

        });
    }

    function makeLineChart(params) {
        var url = GA_REST + '?ids=ga:' + params.ids + '&' + params.query;

        var chart = Highcharts.chart(params.el, {
            CHART_OPTIONS,
            legend: {
                labelFormatter: function() {
                    var total = 0;
                    for(var i=this.yData.length; i--;) { total += this.yData[i]; };
                    return this.name + ' - Total: ' + new Intl.NumberFormat().format(total);
                }
            }
        });

        $.getJSON(url, function(data){

            var categories = arrayColumn(data.rows,0);
            var headers = data.columnHeaders;

            if (headers[0].name == 'ga:date') {
                categories.forEach((item, index) => {
                    categories[index] = formatDate(item);
                });
            }

            chart.xAxis[0].update({categories:categories}, true);
            chart.setTitle({text: params.title});

            for (var i = 1; i < data.rows[0].length; i++) {
                chart.addSeries({
                    type: params.type,
                    name: formatSeriesName(headers[i].name),
                    data: arrayColumn(data.rows,i)
                });
            }

        });
    }

    function formatSeriesName(str) {
        var returnStr = str;
        returnStr = returnStr.replace('ga:','');
        returnStr = capitalizeTxt(returnStr.replace(/([A-Z])/g, ' $1'));
        return returnStr;
    }


    function formatSeriesValue(value, type) {

        var returnValue;

        switch (type) {
            case 'STRING':

            break;
            case 'INTEGER':
                returnValue = parseInt(value).toLocaleString();
            break;
            case 'TIME':
                returnValue = value.toHHMMSS();
            break;
            case 'PERCENT':
                returnValue = parseFloat(value).toFixed(2) + '%';
            break;
        }

        return returnValue;
    }

    String.prototype.toHHMMSS = function () {
        var sec_num = parseInt(this, 10); // don't forget the second param
        var hours   = Math.floor(sec_num / 3600);
        var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
        var seconds = sec_num - (hours * 3600) - (minutes * 60);

        if (hours   < 10) {hours   = "0"+hours;}
        if (minutes < 10) {minutes = "0"+minutes;}
        if (seconds < 10) {seconds = "0"+seconds;}
        return hours+':'+minutes+':'+seconds;
    }


    function makePieChart(params) {
        var url = GA_REST + '?ids=ga:' + params.ids + '&' + params.query;

        $.getJSON(url, function(data){

            var categories = arrayColumn(data.rows,0);
            var headers = data.columnHeaders;
            var chartData = [];

            for (var i = 0; i < data.rows.length; i++) {
                chartData.push({name: capitalizeTxt(data.rows[i][0]), y: parseInt(data.rows[i][1])});
            }

            var chart = Highcharts.chart(params.el, {
                CHART_OPTIONS,
                chart: {
                    plotBackgroundColor: null,
                    plotBorderWidth: null,
                    plotShadow: false,
                    type: 'pie'
                },

                title: {
                    text: params.title
                },

                tooltip: {
                    pointFormat: '{point.percentage:.1f}%'
                },

                series: [{
                    name: params.title,
                    colorByPoint: true,
                    data: chartData
                }]
            });

        });
    }

    function makeTable(params) {

        if (params.source == 'GA') {
            var url = GA_REST + '?ids=ga:' + params.ids + '&' + params.query;
            $.getJSON(url, function(data) {

                var table_headers       = data.columnHeaders;
                var table_rows          = data.rows;
                var template            = '<div class="table-responsive"><table>';
                var header_percentage   = '';

                template += '<thead><tr>';
                table_headers.forEach((item, index) => {
                    if (index == 0) header_percentage = '60%';
                    if (index == 1) header_percentage = '15%';
                    if (index == 2) header_percentage = '25%';
                    template += `<th style="width: ${header_percentage}">${formatSeriesName(item.name)}</th>`;
                });
                template += '</tr></thead>';

                template += '<tbody>';
                table_rows.forEach(item => {
                    template += `<tr>
                                    <td>${item[0]}</td>
                                    <td>${formatSeriesValue(item[1], table_headers[1].dataType)}</td>
                                    <td>${formatSeriesValue(item[2], table_headers[2].dataType)}</td>
                                </tr>`;
                });
                template += '</tbody>';


                template += '</table></div>';

                $(params.el).html(template);

            });
        }



        if (params.source == 'funnelback') {
            var url = FUNNELBACK_REST + '?q=' + window.btoa(FUNNELBACK_COLLECTION + '/' + params.query + '&profile=' + params.ids);
            $.getJSON(url, function(data) {

                var table_rows          = data.data.queries;
                var template            = '<div class="table-responsive"><table>';

                template += '<thead><tr>';
                template += '<th style="width: 80%">Query</th><th style="width: 20%">Count</th>';
                template += '</tr></thead>';

                template += '<tbody>';

                table_rows.forEach(item => {
                    template += `<tr>
                                    <td>${item.query}</td>
                                    <td>${item.count}</td>
                                </tr>`;
                });
                template += '</tbody>';


                template += '</table></div>';

                $(params.el).html(template);

            });

        }
    }


    var $GA_Graphs = $('.interactive-ga-container');

    $GA_Graphs.each(function(index, el) {
        //Loading
        $(el).html('<div class="analytics-loader"></div>');

        if (index == 0) {
            renderToolbar(el);
        }

        var type    = el.getAttribute('data-type');
        var query   = el.getAttribute('data-query');
        var id      = el.getAttribute('data-id');
        var title   = el.getAttribute('data-title');
        var source  = el.getAttribute('data-source') || 'GA';

        var params = {
            el:     el,
            title:  title,
            ids:    id,
            type:   type,
            query:  query,
            source: source
        };


        if (params.type == '' || params.query == '') {
            return false;
        }

        switch (params.type) {
            case 'table':
                makeTable(params);
                break;
            case 'line':
            case 'spline':
            case 'area':
            case 'areaspline':
                makeLineChart(params);
                break;
            case 'pie':
                makePieChart(params);
                break;
            case 'column':
                makeColumnChart(params);
                break;
            case 'bar':
                makeBarChart(params);
                break;
        }

    });

    function renderToolbar(el) {
        var mainContent = document.querySelector('.main-content-wrapper'),
            toolbarFragment = document.createDocumentFragment(),
            toolbar,
            printButton,
            analyticsMenu,
            anchors = document.querySelectorAll('.graph-link'),
            $analyticsMenu = $('<select class="analytics__menu"></select>'),
            $anchors = $('.graph-link'),
            $toolbar = $(document.getElementById('analytics-toolbar'));

        // Create toolbar
        toolbar = document.createElement('div');
        toolbar.id = 'analytics-toolbar';
        toolbar.classList.add('analytics__toolbar');
        toolbarFragment.appendChild(toolbar);

        // Create print button
        printButton = document.createElement('button');
        printButton.setAttribute('type', 'button');
        printButton.classList.add('btn');
        printButton.classList.add('btn__small');
        printButton.classList.add('btn--print');
        printButton.appendChild(document.createTextNode('Print Full Report'));
        printButton.addEventListener('click', function() {
            window.print();
        }, false);
        toolbar.appendChild(printButton);

        // Create analytics menu
        analyticsMenu = document.createElement('select');
        // Attach anchor navigation to select menu
        analyticsMenu.addEventListener('change', function() {
            location.href = '#' + this.options[this.selectedIndex].value;
        }, false);
        // Populate select menu with page anchors
        [].forEach.call(anchors, function(anchor) {
            var option = document.createElement('option');
            option.value = anchor.getAttribute('name');
            option.text = anchor.text;
            analyticsMenu.appendChild(option);
        });
        toolbar.appendChild(analyticsMenu);

        mainContent.insertBefore(toolbarFragment, mainContent.firstChild);

    }

}(jQuery));

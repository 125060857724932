(function ($) {
    'use strict';
    //clear button control
    var $searchBtn = $(".search__submit--icon"),
        $clearBtn = $(".search__submit--cancel"),
        $inputBox = $(".search__input");

    //filter button control
    var $filterBtn = $(".search__filter-btn"),
        $filterBtnText = $(".filter-btn__text"),
        $filter = $(".search-filters"),
        $filterDefaultTab = $(".filter-tab__header-wrap#tab1"),
        $defaultFilter = $(".search-filters__filter-tab[data-tab-index=1]"),
        $filterCloseBtn = $(".search-filters__close");

    var filterBtnActiveClass = "search__filter-btn--active";

    var mainFilterActive = 'search-filter--active';

    var filterBtnActiveText = "Hide filters",
        filterBtnDefaultText = "Show filters";

    //class names
    var active = "active",
        selected = "selected";

    $filterBtn.click(function (e) {
        e.preventDefault();

        $(this).blur();

        if ($(this).hasClass(filterBtnActiveClass)) {
            $filterBtnText.text(filterBtnDefaultText);
            $("html").removeClass("no-scroll");
        } else {
            $filterBtnText.text(filterBtnActiveText);
        }
        $(this).toggleClass(filterBtnActiveClass);
        $filter.toggleClass(active);
        $filterDefaultTab.toggleClass(active);
        $defaultFilter.toggleClass(active);

        if (window.innerWidth < 1024) {
            $("html").toggleClass("no-scroll");
            $('.main').addClass(mainFilterActive);
        }
    });

    $filterCloseBtn.click(function (e) {
        e.preventDefault();
        $filter.removeClass(active);
        $("html").removeClass("no-scroll");
        $filterDefaultTab.removeClass(active);
        $defaultFilter.removeClass(active);

        if ($filterBtn.hasClass(filterBtnActiveClass)) {
            $filterBtnText.text(filterBtnDefaultText);
            $filterBtn.removeClass(filterBtnActiveClass);
            $('.main').removeClass(mainFilterActive);
        } else {
            $filterBtnText.text(filterBtnActiveText);
        }
    });

    $(window).resize(function () {
        if (window.outerWidth < 1024 && $filter.hasClass(active)) {
            $("html").addClass("no-scroll");
            $('.main').addClass(mainFilterActive);
        } else if ($filter.hasClass(active)) {
            $("html").removeClass("no-scroll");
            $('.main').removeClass(mainFilterActive);
        }
    });

    //tabs switch
    var $tab__header = $(".filter-tabs__header-wrap .filter-tab__header-wrap"),
        $tabs = $(".search-filters__filter-tab");

    $tab__header.on("click", function (event) {
        $(".filter-tabs__header-wrap .filter-tab__header-wrap.active").removeClass(active);
        $(this).addClass(active);
        var tab_index = $(this).attr("id") != "undefined" ? $(this).attr("id").replace("tab", "") : 1;
        $(".search-filters__filter-tab.active").removeClass(active);
        $(".search-filters__filter-tab[data-tab-index=" + tab_index + "]").addClass(active);
    });

    //dropdown control

    var $dropdownTrigger = $(".filter-field__dropdown-value, .filter-field__expand-select, .filter-field__dropdown-value--checkboxes"),
        $dropdownItem = $(".filter-field__dropdown-list > li"),
        $dropdownCheckbox = $(".filter-field__dropdown-list--checkboxes li");

    var filterFieldSelector = ".filter-field",
        filterFieldsWrapSelector = ".filter-fields__wrap",
        inputSelector = "input";

    $dropdownCheckbox.on('click', function(e) {
        e.preventDefault();
        var option = $('input#' + $(this).data("option")),
            allInputs = $(this).parent('.filter-field__dropdown-list--checkboxes').find('input');

        if($(this).data('option') == 'clear--all') {
            allInputs.prop('checked', false);
        }

        if(option.prop('checked')) {
            option.prop('checked', false);
        } else {
            option.prop('checked', true);
        }

        replaceDropdownText($(this).parents('.filter-field__dropdown--checkboxes'));
    });

    function sortList(ul){
        var new_ul = ul.cloneNode(false);
        var lis = [];
        for(var i = 0; i < ul.childNodes.length; i++){
            if(ul.childNodes[i].nodeName === 'LI') {
                lis.push(ul.childNodes[i]);
            }
        }
        lis.sort(function(a, b){
            if(a.childNodes[1].value == b.childNodes[1].value) return 0;
            if (a.childNodes[1].value == 0) return -1;
            if (b.childNodes[1].value == 0) return 1;

            if (a.childNodes[1].value < b.childNodes[1].value)
                return -1;
            if (a.childNodes[1].value > b.childNodes[1].value)
                return 1;
            return 0;
            // return parseInt(b.childNodes[0].id , 10) - parseInt(a.childNodes[0].data , 10);
        });
        for(var i = 0; i < lis.length; i++) {
            new_ul.appendChild(lis[i]);
        }
        ul.parentNode.replaceChild(new_ul, ul);
    }

    function replaceDropdownText(parent) {
        var parents = $(parent) || $('.filter-field__dropdown-list--checkboxes'),
            span = parents.find('span'),
            allCheckedInputs = parents.find('input:checked'),
            text = '';

        if(allCheckedInputs.length === 0) {
            text = 'Please select...';
        } else if(allCheckedInputs.length === 1) {
            text = 'One item selected';
        } else {
            text = 'Multiple items selected';
        }

        span.contents().filter(function(){
            return this.nodeType == 3;
        })[0].nodeValue = text;
    }

    $dropdownTrigger.on("click", function (e) {
        e.preventDefault();
        e.stopPropagation();
        $(this).parents(filterFieldSelector).toggleClass(active);
    });

    // Hide the dropdown lists when focus is lost.
    $dropdownTrigger.on("blur", function (e) {
        $(this).parents(filterFieldSelector).removeClass('active');
    });

    // Prevent interactions on child elements from triggering the "blur" event.
    $(".filter-field__dropdown-list, .filter-field__dropdown-list--checkboxes li").on("mousedown click", function (e) {
        e.preventDefault();
        e.stopPropagation();
    });

    // Make div focusable
    $('.filter-field__dropdown--checkboxes').attr('tabindex', '0');

    // Hide the multi-select dropdown list when focus is lost.
    $('.filter-field__dropdown--checkboxes').on("blur", function (e) {
        $(this).removeClass('active');
    });

    $dropdownItem.on("click", function (e) {
        e.preventDefault();
        $(this).parent(".filter-field__dropdown-list").siblings(".filter-field__dropdown-value").val($(this).data("option"));
        $(".filter-field__dropdown-list li.selected").removeClass(selected);
        $(this).addClass(selected);
        $(this).parent(".filter-field__dropdown-list").parent(".filter-field__dropdown").removeClass(active);
    });

    //calendar
    var $calendarIcon = $(".filter-field__icon-calendar"),
        $datePicker = $(".datepicker, .daterangepicker");

    $calendarIcon.on("click", function (event) {
        event.preventDefault();
        $(this).siblings(".picker").addClass("picker--opened picker--focused");
        $(this).siblings(".picker__input").addClass("picker__input--active");
    });

    var $pickerSelectYear,
        pickerSelectYearClass = '.picker__select--year',
        pickerLabelYear = 'picker__label--year';

    if ($filter.hasClass('search-filters--dateRange')) {
        $datePicker.each(function (index, el) {
            var $this = $(el);
            $this.pickadate({
                min: [1900, 1, 1],
                max: [(new Date()).getFullYear() + 1, 12, 31],
                selectYears: $this.hasClass('archive') ? 200 : true,
                format: 'dd/mm/yyyy',
                hiddenPrefix: '',
                hiddenSuffix: '',
                formatSubmit: 'yyyymmdd',
                onStart: function () {
                    $pickerSelectYear = $($(this)[0]["$holder"][0]).find(pickerSelectYearClass);
                    $pickerSelectYear.wrap('<label class="' + pickerLabelYear + '">');
                },
                onSet: function () {
                    $pickerSelectYear = $($(this)[0]["$holder"][0]).find(pickerSelectYearClass);
                    $pickerSelectYear.wrap('<label class="' + pickerLabelYear + '">');
                }
            });
        });
    } else {
        $datePicker.each(function (index, el) {
            var $this = $(el);
            $this.pickadate({
                min: [1900, 1, 1],
                max: [(new Date()).getFullYear() + 1, 12, 31],
                selectYears: $this.hasClass('archive') ? 200 : true,
                format: 'dd/mm/yyyy',
                hiddenPrefix: 'meta_',
                hiddenSuffix: '',
                formatSubmit: 'ddmmmyyyy',
                onStart: function () {
                    $pickerSelectYear = $($(this)[0]["$holder"][0]).find(pickerSelectYearClass);
                    $pickerSelectYear.wrap('<label class="' + pickerLabelYear + '">');
                },
                onSet: function () {
                    $pickerSelectYear = $($(this)[0]["$holder"][0]).find(pickerSelectYearClass);
                    $pickerSelectYear.wrap('<label class="' + pickerLabelYear + '">');
                }
            });
        });
    }

    $datePicker.on("change", function () {
        if ($(this).val() !== null && $(this).val() !== "") {
            if ($(this).hasClass("datepicker")) {
                if(!$(this).hasClass('hasValue')) {
                    $(this).addClass('hasValue');
                    $(this).after("<button class='filter__clear datepicker--clear' type='button' data-clear='" + $(this).attr('id') + "' aria-label='Clear selection for previous date'></button>");
                }
                $(this).parent(filterFieldSelector).addClass(active);
            } else if ($(this).hasClass("daterangepicker")) {
                if(!$(this).hasClass('hasValue')) {
                    $(this).addClass('hasValue');
                    $(this).after("<button class='filter__clear daterangepicker--clear' type='button' data-clear='" + $(this).attr('id') + "' aria-label='Clear selection for previous date'></button>");
                }
                $(this).parent(filterFieldsWrapSelector).parent(filterFieldSelector).addClass(active);
            }
        }
    });

    $('.filter-field__date-picker, .filter-field__date-range-pick').on('click', '.filter__clear', function() {
        var $targetInput = '#' + $(this).data('clear');
        $($targetInput).removeClass('hasValue');
        $($targetInput).val("");
        $(this).parent(filterFieldSelector).removeClass(active);
        $(this).remove();
    });

    //if datapicker have entered value on start
    $datePicker.each(function () {
        if ($(this).val()) {
            $(this).closest(".filter-field__date-range-pick").addClass("active");
        }
    });


    //text field
    $(".filter-field__text input").on("keyup", function () {
        if ($(this).val() != null && $(this).val() != "") {
            $(this).parent(filterFieldSelector).addClass(active);
        } else {
            $(this).parent(filterFieldSelector).removeClass(active);
        }
    });

    var $fieldClearBtn = $(".filter-field__clear");
    $fieldClearBtn.on("click", function () {
        if ($(this).siblings(inputSelector).length > 0) {
            $(this).siblings(inputSelector).removeClass('hasValue');
            $(this).siblings(inputSelector).val("");
        } else if ($(this).siblings(filterFieldsWrapSelector).length > 0) {
            $(this).siblings(filterFieldsWrapSelector).removeClass('hasValue');
            $(this).siblings(filterFieldsWrapSelector).children(inputSelector).val("");
        }

        $(this).parent(filterFieldSelector).removeClass(active);
    });

    //sort dropdown
    var $sortOptions = $(".search-results__sort"),
        $sortOption = $(".sort-options__list li"),
        $optionSeled = $(".sort-option--selected");

    var selectedSortOption = "option-selected",
        optionListExpanded = "expanded";

    $sortOptions.on("click", function () {
        event.stopPropagation();
        $(this).toggleClass(optionListExpanded);
    });

    $sortOption.on("click", function () {
        $sortOption.each(function () {
            if ($(this).hasClass(selectedSortOption)) {
                $(this).removeClass(selectedSortOption);
                return false;
            }
        });
        $(this).addClass(selectedSortOption);
        $optionSeled.text($(this).text().trim());
    });

    $(window).click(function () {
        if ($sortOptions.hasClass(optionListExpanded)) {
            $sortOptions.removeClass(optionListExpanded);
        }
    });

    //results view switch
    var $cardsViewBtn = $(".view-switch__cards"),
        $tableViewBtn = $(".view-switch__table"),
        $viewSwitchBtn = $(".view-switch__cards, .view-switch__table"),
        $searchResults = $(".search-results");

    var tableView = "search-results__table-view",
        cardsView = "search-results__cards-view";

    $viewSwitchBtn.on("click", function () {
        if (!$(this).hasClass(selected)) {
            $cardsViewBtn.removeClass(selected);
            $tableViewBtn.removeClass(selected);
            $(this).addClass(selected);
            $searchResults.toggleClass(tableView).toggleClass(cardsView);
        }
    });

    //reset-search-facets
    $(document).on("click", ".reset-search-facets, .reset-search-facets--mobile", function (e) {
        e.preventDefault();
        var $inputsForReset = $(this).closest("form").find(".search__fields-wrap input").not(".planning-scheme-input"),
            $calendarItems = $(this).closest("form").find(".filter-field__date-range-pick"),
            $calendarClear = $(this).closest("form").find('button.filter__clear'),
            $allSpans = $(this).closest("form").find('span.filter-field__dropdown-value--checkboxes');

        $inputsForReset.each(function () {
            $(this).val('');
            $(this).prop('checked', false);
        });

        $calendarItems.each(function () {
            $(this).removeClass('active');
        });

        $calendarClear.each(function() {
            $(this).remove();
        })

        $allSpans.each(function() {
            $(this).contents().filter(function(){
                return this.nodeType == 3;
            })[0].nodeValue = 'Please select...';
        })

        // Reload the page.
        if ($(this).attr('href') !== '') {
            // Use the link href, if it has one.
            window.location = $(this).attr('href');
        } else {
            window.location.search ? window.location = window.location.origin + window.location.pathname : '';
        }
    });

    const cardSelection = {
        searchResults: '.search-results:not(.resource-library-results)',
        cardsBtn: 'view-switch__cards',
        tableBtn: 'view-switch__table',
        selectedClass: 'selected',
        tableViewClass: 'search-results__table-view',
        cardsViewClass: 'search-results__cards-view',
        pushToStorage: false,

        init() {
            this.checkSession();
            this.eventListener();
        },

        eventListener() {
            if (document.querySelector(this.searchResults)) {
                document.querySelector(`.${this.cardsBtn}`).addEventListener('click', this.clickHandler);
                document.querySelector(`.${this.tableBtn}`).addEventListener('click', this.clickHandler);
            }
        },

        checkSession() {
            const dataSession = JSON.parse(sessionStorage.getItem('searchLayout'));
            if (dataSession) {
                dataSession.map(el => {
                    if (el.layout === this.cardsBtn && el.url === window.location.pathname) {
                        this.removeClass(`.${this.tableBtn}`, this.selectedClass);
                        this.addClass(`.${this.cardsBtn}`, this.selectedClass);

                        this.removeClass(this.searchResults, this.tableViewClass);
                        this.addClass(this.searchResults, this.cardsViewClass);

                    } else if (el.layout === this.tableBtn && el.url === window.location.pathname) {
                        this.removeClass(`.${this.cardsBtn}`, this.selectedClass);
                        this.addClass(`.${this.tableBtn}`, this.selectedClass);

                        this.removeClass(this.searchResults, this.cardsViewClass);
                        this.addClass(this.searchResults, this.tableViewClass);
                    }
                });
            }
        },

        clickHandler() {
            const layoutValue = this.classList[0],
                seesionValue = JSON.parse(sessionStorage.getItem('searchLayout'));

            if (seesionValue) {
                cardSelection.pushToStorage = true;

                seesionValue.map(el => {
                    el.url === window.location.pathname ? el.layout = layoutValue : cardSelection.pushToStorage = true;
                    el.url === window.location.pathname ? cardSelection.pushToStorage = false : '';
                });

                cardSelection.pushToStorage ? seesionValue.push({
                    'layout': layoutValue,
                    'url': window.location.pathname
                }) : '';

                sessionStorage.setItem('searchLayout', JSON.stringify(seesionValue));
            } else {
                const initSeesionValue = [{
                    'layout': layoutValue,
                    'url': window.location.pathname
                }];
                sessionStorage.setItem('searchLayout', JSON.stringify(initSeesionValue));
            }
        },

        removeClass(selector, removeClass) {
            document.querySelector(selector).classList.remove(removeClass);
        },

        addClass(selector, addClass) {
            document.querySelector(selector).classList.add(addClass);
        }
    };

    cardSelection.init();

    const resetButtons = {
        viewAllBtn: document.getElementById('view-all-btn') ? document.getElementById('view-all-btn') : null,
        clearAllBtn: document.getElementById('clear-all-btn') ? document.getElementById('clear-all-btn') : null,
        activeBtn: 'search__clear-btn--active',

        init() {
            this.showButton();
            this.viewAllBtn ? this.viewAllBtn.addEventListener('click', this.clickHandler) : '';
            this.clearAllBtn ? this.clearAllBtn.addEventListener('click', this.clickHandler) : '';
        },

        showButton() {
            if (resetButtons.viewAllBtn) {
                this.isQueryEmpty(this.getParams(window.location.href)) ? '' : resetButtons.viewAllBtn.classList.add(resetButtons.activeBtn);
            }
        },

        getParams(url) {
            var params = {};

            if (url.indexOf('?') != -1) {
                var parser = document.createElement('a');
                parser.href = url;
                var query = parser.search.substring(1);
                var vars = query.split('&');
                for (var i = 0; i < vars.length; i++) {
                    var pair = vars[i].split('=');
                    params[pair[0]] = decodeURIComponent(pair[1]);
                }
            }

            return params;
        },

        isQueryEmpty(query) {

            var isEmpty = true;

            for (var key in query) {
                if (query[key] != '') {
                    isEmpty = false;
                    return isEmpty;
                }
            }

            return isEmpty;

        },

        clickHandler() {
            window.location.search ? window.location = window.location.origin + window.location.pathname : '';
        }
    };

    resetButtons.init();

    // Ensures valeus appear as selected if the user has already
    var allDatepickers = $('.picker__input'),
        allCheckboxes = $('.filter-field__dropdown--checkboxes'),
        allDropdowns = $('ul.filter-field__dropdown-list--checkboxes');

    allDatepickers.each(function(i) {
        if ($(allDatepickers[i]).val() !== null && $(allDatepickers[i]).val() !== "") {
            if ($(allDatepickers[i]).hasClass("datepicker")) {
                if(!$(allDatepickers[i]).hasClass('hasValue')) {
                    $(allDatepickers[i]).addClass('hasValue');
                    $(allDatepickers[i]).after("<button class='filter__clear datepicker--clear' type='button' data-clear='" + $(this).attr('id') + "' aria-label='Clear selection for previous date'></button>");
                }
                $(allDatepickers[i]).parent(filterFieldSelector).addClass(active);
            } else if ($(allDatepickers[i]).hasClass("daterangepicker")) {
                if(!$(allDatepickers[i]).hasClass('hasValue')) {
                    $(allDatepickers[i]).addClass('hasValue');
                    $(allDatepickers[i]).after("<button class='filter__clear daterangepicker--clear' type='button' data-clear='" + $(this).attr('id') + "' aria-label='Clear selection for previous date'></button>");
                }
                $(allDatepickers[i]).parent(filterFieldsWrapSelector).parent(filterFieldSelector).addClass(active);
            }
        }
    })

    allCheckboxes.each(function(i) {
        replaceDropdownText(allCheckboxes[i]);
    });

    allDropdowns.each(function() {
        sortList(this);
    });

    /**
     * Search results sorting options.
     * Gets the sort parameter from an attribute on the clickable element.
     * That is then inserted into a hidden sorting input field that is submitted with the rest of the filters form.
     * Assumes usage of Funnelback metadata sorting queries. See: https://docs.funnelback.com/15.24/customise/metadata-customisation/metadata-class-types.html
     *
     * Assumed markup for form:
     * <div class="search-filters"><form action="/" method="get"><input type="hidden" name="sort" value="" /></form></div>
     *
     * Assumed markup for buttons:
     * <div class="js-result-sort result-row__cell sortable" data-sort="displayAmendmentID"></div>
     */

    function updateSortingHeaders() {
        // Check if any sorting has been applied and add styles as necessary.
        if ($('.js-result-sort').length > 0) {

            // Get the sorting value from the hidden text field.
            var hiddenSortValue = $('.search-filters form').children('input[name="sort"]').val(),
                isSortDescending = true,
                sortingSplitArray = [],
                sortValue = '';

            // Extract the relevant part for the metadata sort value.
            sortingSplitArray = hiddenSortValue.split('meta');

            if (sortingSplitArray.length > 1) {

                sortValue = sortingSplitArray[1];
            } else {

                // Skip this function if there is no sorting applied or is not accepted parameter.
                return;
            }

            // Check if we are sorting by ascending or descending order.
            if (hiddenSortValue.indexOf('dmeta') !== -1) {
                isSortDescending = true;
            } else {
                isSortDescending = false;
            }

            // Look for a matching button and update its styles.
            $('.js-result-sort').each(function(){

                if ($(this).attr('data-sort') === sortValue) {

                    $(this).addClass('sort');

                    if (isSortDescending) {
                        $(this).addClass('descending');
                    }

                    return false;
                }
            });
        }
    }

    // Add styles to sorting buttons.
    updateSortingHeaders();

    // Respond to clicks on the sorting buttons.
    $('.js-result-sort').on('click', onResultSortClick);

    function onResultSortClick(evt) {
        evt.preventDefault();

        // Find the hidden sorting input.
        var filtersForm = document.querySelector('.search-filters form'),
            persistentSort = filtersForm.querySelector('input[name="sort"]'),
            sortableHeaders = $('.js-result-sort'),
            isSortDescending = true,
            sortValue = '',
            sortQuery = '';

        // Remove active classes from all.
        sortableHeaders.removeClass('sort');
        sortableHeaders.not($(this)).removeClass('descending');

        // Add active class to current.
        $(this).addClass('sort');

        // Add correct sort direction styling to current.
        if ($(this).hasClass('descending')) {
            $(this).removeClass('descending');
            isSortDescending = false;
        } else {
            $(this).addClass('descending');
            isSortDescending = true;
        }

        // Generate the sorting query string
        sortValue = $(this).attr('data-sort');

        if (isSortDescending) {
            sortQuery = 'dmeta';
        } else {
            sortQuery = 'meta';
        }

        sortQuery += sortValue;

        // Set persistent values
        persistentSort.setAttribute('value', sortQuery);

        // Submit the filters form.
        filtersForm.submit();
    }

    const searchAutocomplete = {
        sel: {
            search: '#search__query-autocomplete',
            extraQuery: '#extraQuery',
            filtersSearchMode: '#filters-search-mode',
            searchMenu: '.search__input--menu',
            searchHint: '.search__input--hint',
            main: '.main',
            searchIcon: '.search__submit--icon',
            searchCancel: '.search__submit--cancel',
            form: '.property-information form',
            searchDataset: '.search__input--dataset',
            searchCursor: '.search__input--cursor',
            searchModeTogglers: '.search__mode-radio',
        },
        vars: {
            classNames: 'search__input',
            url: '',
            wideBreakpoint: 1024,
            viewAll: 'View all results',
            arrowSelect: true,
            emptyResult: $('.property-information').length
                ? 'No match has been found'
                : $('.js-standard-suggest-error').length
                    ? 'No match has been found'
                    : $('.resource-library-results').length
                        ? 'No results found'
                        : 'No matching<br>Address found.',
            keys: {
                ENTER: 13,
                KEY_DOWN: 40,
                KEY_UP: 38,
                TAB: 9
            },
            searchMode: 'id',
            suggestionProfile: '',
        },
        init() {
            this.setSearchMode(this.getSearchMode());
            this.setSuggestionURL(this.getSuggestionURL());
            this.initTypehead();
            this.setAutosuggestionWidth();
            this.clearInput();
            this.searchIcon();
            this.sendQuery();
            this.viewAllResults();
            this.scrollOnArrowKeys();
        },
        initTypehead() {
            const self = this;
            $(this.sel.search).typeahead({
                name: 'site-search',
                hint: true,
                highlight: true,
                minLength: $('.js-amendment-search').length ? 2 : 3, // Trigger the autosuggest after 2 characters for Amendment search pages.
                classNames: {
                    input: self.vars.classNames + '--input',
                    hint: self.vars.classNames + '--hint',
                    menu: self.vars.classNames + '--menu',
                    dataset: self.vars.classNames + '--dataset',
                    suggestion: self.vars.classNames + '--suggestion',
                    empty: self.vars.classNames + '--empty',
                    open: self.vars.classNames + '--open',
                    cursor: self.vars.classNames + '--cursor',
                    highlight: self.vars.classNames + '--highlight',
                    selectable: self.vars.classNames + '--selectable'
                }
            }, {
                name: 'data',
                async: true,
                limit: 20,
                source: function (query, sync, async) {
                    self.fetchData(query, async)
                },
                templates: {
                    suggestion: function (data) {
                        if (typeof data.disp !== 'undefined') {
                            return `<div>${data.disp}</div>`;
                        } else if (typeof data.text !== 'undefined') {
                            return `<div>${data.text}</div>`;
                        } else {
                            return `<div>${data}</div>`;
                        }
                    },
                    notFound: function () {
                        return `<div class="${self.vars.classNames}--empty">${self.vars.emptyResult}</div>`;
                    }
                }
            });

            $(this.sel.search).bind('typeahead:cursorchange', function (e, suggestion) {
                if (typeof suggestion !== 'undefined') {
                    if (typeof suggestion.disp !== 'undefined') {
                        $(this).val(suggestion.disp);
                    } else if (typeof suggestion.text !== 'undefined') {
                        $(this).val(suggestion.text);
                    } else {
                        $(this).val(suggestion);
                    }
                }
            });

            $(this.sel.searchModeTogglers).on('change', (ev) => {
                const searchMode = ev.currentTarget.getAttribute('value');
                self.setSearchMode(searchMode);
                self.setSuggestionURL(self.getSuggestionURL());
            });

        },
        fetchData(query, cb) {
            const self = this,
                url = this.vars.url + encodeURIComponent(query);
            fetch(url)
                .then(res => res.json())
                .then((res) => {
                    cb(res);
                });
        },
        getSuggestionURL() {
            const params = {
                extraQuery: $(this.sel.extraQuery).length
                    ? $(this.sel.extraQuery).val()
                    : '',
                profile: this.vars.suggestionProfile,
                partial_query: ''
            };
            // Use default Funnelback auto-suggestion profile
            if (!params.profile.length) {
                delete params.profile;
            }
            const url = $(this.sel.search).attr('data-typeahead')
                + '?' + Object.keys(params).reduce((accumulator, paramKey, paramIndex) => {
                    const prevParams = paramIndex === 1
                        ? `${accumulator}=${params[paramKey]}`
                        : accumulator;
                    return(`${prevParams}&${paramKey}=${params[paramKey]}`);
            });
            return(url);
        },
        setSuggestionURL(url) {
            this.vars.url = url;
        },
        getSearchMode() {
            const togglers = document.querySelectorAll(this.sel.searchModeTogglers);
            const checkedToggler = !!togglers
                ? [...togglers].find(radio => radio.checked)
                : this.vars.searchMode;
            const currentMode = !!checkedToggler
                ? checkedToggler.getAttribute('value')
                : this.vars.searchMode;
            return(currentMode);
        },
        setSearchMode(mode) {
            /*
             * Map search modes to Funnelback auto-suggestion profiles
             * e.g. search_mode=id in the Matrix front-end maps to
             * profile=amendment-id in Funnelback suggest.json
             */
            const profileMap = {
                id: 'amendment-id'
            };
            this.vars.searchMode = mode;
            this.vars.suggestionProfile = !!profileMap[mode]
                ? profileMap[mode]
                : '';
            $(this.sel.filtersSearchMode).val(mode);
        },
        setAutosuggestionWidth() {
            if (window.innerWidth < this.vars.wideBreakpoint) {
                $(this.sel.searchMenu).css({
                    'width': $(this.sel.main).width() + 'px',
                    'left': '-10px'
                });
            }
        },
        clearInput() {
            $(this.sel.searchCancel).on('click', () => {
                $(this.sel.search).val('');
                $(this.sel.search).css('text-indent', '0');
                $(this.sel.searchHint).val('');
                $(this.sel.search).typeahead('val', '');
                $(this.sel.search).focus();
                $(this.sel.searchCancel).hide();
                $(this.sel.searchIcon).show();
            });
        },
        searchIcon() {
            $(this.sel.searchIcon).on('click', () => {
                $(this.sel.search).focus();
            });
        },
        sendQuery() {
            const self = this;
            $(this.sel.search).bind('typeahead:select', function (e, suggestion) {
                $(this).css('background-color', '#f3f3f3');
                if (typeof suggestion.disp !== 'undefined') {
                    $(this).typeahead('val', suggestion.disp);
                    $(this).val(suggestion.disp);
                } else if (typeof suggestion.text !== 'undefined') {
                    $(this).typeahead('val', suggestion.text);
                    $(this).val([suggestion.text, suggestion.key]);
                } else {
                    $(this).typeahead('val', suggestion);
                    $(this).val(suggestion);
                }
                $(self.sel.search).parents('form').submit();
                self.clearInput();
            });

        },
        scrollOnArrowKeys() {
            const self = this,
                dataSet = $(this.sel.searchDataset),
                constSelectableHeight = 63;
            let cursor, storeResult;

            $(this.sel.search).bind('keypress', function () {
                if (!$(this).val().length) {
                    self.vars.arrowSelect = true;
                }
            });
            $(this.sel.search).bind('keydown', function (e) {
                cursor = $(self.sel.searchCursor);
                storeResult = cursor.length ? parseInt(cursor.css('height'), 10) : constSelectableHeight;

                if ($(this).length && cursor.length && !self.vars.arrowSelect) {



                    if (e.which === self.vars.keys.KEY_DOWN) {

                        $(this).css('text-indent', '0');
                        dataSet[0].scrollTop = dataSet[0].scrollTop + storeResult;
                    }
                    if (e.which === self.vars.keys.KEY_UP) {
                        $(this).css('text-indent', '0');
                        dataSet[0].scrollTop = dataSet[0].scrollTop - storeResult;
                        if (dataSet[0].scrollTop <= 0) {
                            dataSet[0].scrollTop = 0;
                        }
                    }
                } else if (cursor.length) {
                    self.vars.arrowSelect = false;
                    dataSet[0].scrollTop = 0;
                } else {
                    dataSet[0].scrollTop = 0;
                }
            });
        },
        viewAllResults() {
            $('body').on('click', '.' + this.vars.classNames + '--view-all', () => {
                $(self.sel.search).parents('form').submit();
            });
        }
    };

    searchAutocomplete.init();

    // search-tabs
    var $accordionButton = $(".search-tabs__selected-tab");

    var accordionItem = ".search-tabs__item",
        accordionItemActive = ".search-tabs__item.active",
        accordionButton = ".search-tabs__selected-tab",
        accordionItemsWrapper = ".search-tabs-wrap",
        accordionTemplate = ".search-tabs";

    var activeItem,
        selectedItemVal;

    // when clicked accordion button
    $accordionButton.on("click", function () {
        $(this).toggleClass("active");
        $(this).next().toggleClass("mobile-hidden");
    });

    // when click outside of the accordion button
    /iP/i.test(navigator.userAgent) && $('*').css('cursor', 'pointer');
    $(document).on("click touch", function (e) {
        // console.log(e.target)
        if (!e.target.classList.contains('search-tabs__selected-tab') && !e.target.classList.contains('search-tabs-wrap') && !e.target.classList.contains('search-tabs__item') && !$(accordionItemsWrapper).hasClass("mobile-hidden")) {
            $(accordionItemsWrapper).addClass("mobile-hidden");
        }
    });

    // when clicked accordion item
    $(document).ready(function () {
        $(accordionItem).each(function () {
            var $this = $(this)
            if ($this.hasClass("active")) {
                var activeAccordion = $this.text();
                $this.closest(accordionTemplate).find(accordionButton).text(activeAccordion)
            }
        });
    });

    $(document).on("click", accordionItem, function () {
        if ($(this).parent().children().hasClass("active")) {
            $(this).parent().children().removeClass("active");
            selectedItemVal = $(this).addClass("active").text();
            $(this).closest(accordionItemsWrapper).addClass("mobile-hidden");
            $(this).closest(accordionTemplate).find(accordionButton).removeClass("active").text(selectedItemVal);
            // show loader when page is loading
            var $loader = $('.load-container');
            $loader.show();
        }
    });

}(jQuery));

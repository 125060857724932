(function($){
    'use strict';

    const defaultOptions = {
        accessibility: false,
        centerMode: false,
        focusOnSelect: false,
        infinite: false,
        infinite: true,
        slidesToScroll: 1,
        touchMove: true,
        variableWidth: true,
    }

    const creatCarousel = (el, customOptions = {}) => {
        const options = Object.assign(defaultOptions, customOptions)
        $(el).slick(options)
    }

    const destroyCarousel = (el) => {
        el.hasClass('slick-initialized') && el.slick('unslick')
    }

    const mobileViewItems = (items) => (
        Array.from(items).map((item, index) => (`
            <div class="full-width-carousel__content">
                <div class="full-width-carousel__content-item full-width-carousel__content-item--small">
                    ${item.innerHTML}
                </div>
            </div>
        `)).join('')
    )

    const mobileView = (markup, items, settings) => {
        const result = `
        <div class="full-width-carousel-mobile full-width-carousel__container">
            <div class="row">
                <div class="col-xs-12">
                    <div id="full-width-carousel-mobile" class="full-width-carousel__items">
                        ${mobileViewItems(items)}
                    </div>
                </div>
            </div>
        </div>`

        markup.innerHTML = result;

        creatCarousel(markup.querySelector(settings.mobileCarousel), {
            dots: true,
            arrows: false,
        })
    }

    const desktopViewSmall= (items, parentIndex) => (
        Array.from(items).map((item, index) => (
            index % 3 !== 0 && index > parentIndex && index <= parentIndex + 2 ? `
            <div class="col-xs-12">
                <div class="full-width-carousel__content-item full-width-carousel__content-item--small">
                    ${item.innerHTML}      
                </div>
            </div>` : null
        )).join('')
    )

    const desktopViewLarge = (item) => (
        ` <div class="col-xs-12 col-sm-6 full-width-carousel__content">
            <div class="full-width-carousel__content-item full-width-carousel__content-item--large">
                ${item.innerHTML}      
            </div>
        </div>`
    )

    const desktopViewItem = (items) => (
        Array.from(items).map((item, index) => (
            index % 3 === 0 ? `
                <div class="full-width-carousel__item">
                    <div class="full-width-carousel__container">
                        <div class="row">
                            ${desktopViewLarge(item)}
                            <div class="col-xs-12 col-sm-6 full-width-carousel__content">
                                <div class="row">
                                    ${desktopViewSmall(items, index)}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ` : null
        )).join('')
    )

    const desktopView = (markup, items, settings) => {
        const result = `
        <div id="full-width-carousel-desktop" class="full-width-carousel-desktop full-width-carousel__items">
            ${desktopViewItem(items)}
        </div>
        `
        markup.innerHTML = result;

        creatCarousel(markup.querySelector(settings.desktopCarousel), {
            arrows: true,
            dots: false
        })
    }

    const initView = (settings = {}) => {
        const mobileViewport = window.matchMedia(settings.mastchMedia);
        const markup = typeof settings.carousel === 'string' ? document.querySelector(settings.carousel) : settings.carousel

        if(!markup) return
        const items = markup.querySelectorAll(settings.linksWrapper)

        mobileViewport.matches ? desktopView(markup, items, settings) : mobileView(markup, items, settings) 
        
        mobileViewport.addListener(mq => {
            mq.matches ? desktopView(markup, items, settings) : mobileView(markup, items, settings)
        });
    }

    initView({
        mastchMedia: "screen and (min-width: 768px)",
        carousel: '#full-width-carousel',
        linksWrapper: '#full-width-carousel-link',
        mobileCarousel: '#full-width-carousel-mobile',
        desktopCarousel: '#full-width-carousel-desktop'
    })

    
}(jQuery));
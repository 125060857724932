(function(){
    'use strict';

    const grantsFilters = {
    	settings: {
    		grants:      'grants__filter--group',
    		surfixLink:  'link',
    		activeClass: 'active',
    		chevron:     'search__chevron',
    		surfixUp:    '--up',
    		noList:      'search__filter--no-filter'
    	},
    	init: () => {
    		const _this = grantsFilters,
    		mainComponent = document.querySelectorAll(`.${_this.settings.grants} ul a`),
    		menuLink = Array.prototype.filter.call(mainComponent, _this.menuFilter);

    		_this.addEventListner(menuLink);
    	},
    	menuFilter: (el) => {
    		const _this = grantsFilters;
    		let elClass = el.getAttribute('class');

    		if (elClass !== null && elClass.indexOf(`${_this.settings.surfixLink}`)) {
    			return el;
    		}
    	},
    	addEventListner: (el) => {
    		const _this = grantsFilters;

    		el.forEach( function(element) {
    			element.addEventListener('click', _this.eventHandler);
    			_this.addDisabledClass(element);
    		});
    	},
    	eventHandler: (el) => {
    		el.preventDefault();
    		const _this = grantsFilters,
    		chevron = el.target.querySelector(`.${_this.settings.chevron}`),
    		list = el.target.parentNode.parentNode,
    		subList = el.target.parentNode.querySelector(`ul`);
    		
    		if(el.target.parentNode.querySelector('ul')) {
	    		//Animate chevron
	    		_this.togleActiveClass(chevron, `${_this.settings.chevron}${_this.settings.surfixUp}`);
	    		//Expands list
	    		_this.togleActiveClass(list, `${_this.settings.activeClass}`);
	    		_this.togleActiveClass(subList, `${_this.settings.activeClass}`);
	    	}

    	},
    	togleActiveClass: (el, className) => {
    		if (el.classList) {
    			el.classList.toggle(className);
			} else {
				var classes = el.className.split(' ');
				var existingIndex = classes.indexOf(className);

				if (existingIndex >= 0)
					classes.splice(existingIndex, 1);
				else
				    classes.push(className);

			  el.className = classes.join(' ');
			}
    	},
    	addDisabledClass: (el) => {
    		const _this = grantsFilters;

    		if(!el.parentNode.querySelector('ul')) {
    			const list = el.parentNode.parentNode,
    			className = _this.settings.noList;

    			if (list.classList)
				  list.classList.add(className);
				else
				  list.className += ' ' + className;
    		}
    	}
    };

    grantsFilters.init();
    
}());
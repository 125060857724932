(function($){
    const videoBanner = {
        settings: {
            banner: 'active-video-banner__video-bg--loop',
            button:   'active-video-banner__toggle-button'
        },
        init: () => {
            const _this = videoBanner;

            if (document.querySelector(`.${_this.settings.button}`)) {
                const button = document.querySelector(`.${_this.settings.button}`);
                button.addEventListener('click', _this.eventHandler);
            }
        },

        buttonToggle: (video) => {
            const _this = videoBanner;
            const button = document.querySelector(`.${_this.settings.button}`);

            if (!video) {
                button.classList.add('pause');
            } else {
                button.classList.remove('pause');
            }
        },

        eventHandler: () => {
            const _this = videoBanner;
            const video = document.querySelector(`.${_this.settings.banner}`);

            if (video.paused === false) {
                _this.buttonToggle(video.paused);
                video.pause();
            } else {
                _this.buttonToggle(video.paused);
                video.play();
            }
        }
    };

videoBanner.init();
    
}(jQuery));
/*global jQuery*/
(function ($) {
    'use strict';

    var $iframeElement = $('.content-wrapper iframe');

    $iframeElement.each(function () {
        $(this).wrap('<div class="content-wrapper__col-media"></div>');
    });
}(jQuery));

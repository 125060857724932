const trendingTabs = document.querySelector('.trending-tabs');
const trendingContent = document.querySelectorAll('.trending-content');

if (trendingTabs) {
    //Add listener to each tab
    [...trendingTabs.children].map(tab => {
        tab.addEventListener('click', () => {
            //Hide trending-content
            [...trendingContent].map(trending => trending.classList.add('hidden'));
            const tabID = tab.id.substring(0, tab.id.indexOf('__'));

            //Show selected trending-content
            [...trendingContent].map(trending => {
                if (trending.id === tabID) {
                    trending.classList.remove('hidden');
                }
            });
            //Unactive all tabs
            [...trendingTabs.children].map(hideTab => hideTab.classList.remove('trending-tabs__header--active'));

            //Active clicked tab
            tab.classList.add('trending-tabs__header--active');
        })
    });
}

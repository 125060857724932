(function ($) {
    'use strict';

    var $addressPicker = $('#hero-address-picker');
    
    const get = function(obj, key) {
        return key.split(".").reduce(function(o, x) {
            return (typeof o == "undefined" || o === null) ? o : o[x];
        }, obj);
    }


    if ($addressPicker.length) {
        var payload;
        
        var vhrFoundHtml                = $('#vhrFound').html();
        var vhrNotFoundHOFoundHtml      = $('#vhrNotFoundHOFound').html();
        var vhrNotFoundHONotFoundHtml   = $('#vhrNotFoundHONotFound').html();

        window.addEventListener("message", function(msg){  
            if (msg && msg.data && msg.data.type =='HeroPlacePicked'){
                payload = msg.data.payload;

                $('html, body').animate({
                    scrollTop: $('#map-result').offset().top - 100
                }, 500);

                console.log(payload)   

                // variables
                var vhrNumber           = payload.vhrNumber || '',
                    siteName            = payload.siteName || '',
                    hermesNumber        = payload.hermesNumber || '',
                    pfi                 = payload.pfi || '',
                    lgaName             = payload.lgaName || '',
                    unstructuredAddress = (payload.unstructuredAddress) ? "(" + payload.unstructuredAddress + ")" : '',
                    unitNo              = get(payload, 'address.unitNo') || '',
                    streetNo            = get(payload, 'address.streetNo') || '',
                    streetName          = get(payload, 'address.streetName') || '',
                    streetType          = get(payload, 'address.streetType') || '',
                    suburb              = get(payload, 'address.suburb') || '',
                    state               = get(payload, 'address.state') || '',
                    postcode            = get(payload, 'address.postcode') || '',
                    country             = get(payload, 'address.country') || '';


                if (vhrNumber) {
                    // hide other
                    $('.outcome-condition').hide();
                    
                    var target = $('#vhrFound');
                    
                    //set it back to original
                    target.html(vhrFoundHtml);
                   
                    target.html(target.html().replace(/{vhrNumber}/g, vhrNumber));
                    target.html(target.html().replace(/{siteName}/g, siteName));
                    target.html(target.html().replace(/{address}/g, unstructuredAddress));
                    target.html(target.html().replace(/{formLink}/g, 
                                                        "?vhrNumber=" + encodeURIComponent(vhrNumber) + 
                                                        "&siteName=" + encodeURIComponent(siteName) +
                                                        "&hermesNumber=" + encodeURIComponent(hermesNumber) +
                                                        "&unitNo=" + encodeURIComponent(unitNo) +
                                                        "&streetNo=" + encodeURIComponent(streetNo) +
                                                        "&streetName=" + encodeURIComponent(streetName + ' ' + streetType) +
                                                        "&suburb=" + encodeURIComponent(suburb) +
                                                        "&state=" + encodeURIComponent(state) +
                                                        "&postcode=" + encodeURIComponent(postcode) +
                                                        "&country=" + encodeURIComponent(country)
                                                    ));

                    target.show();

                }

                if (!vhrNumber && foundHeritage(payload.overlays)) {
                    // hide other
                    $('.outcome-condition').hide();
                    
                    var target = $('#vhrNotFoundHOFound');

                    //set it back to original
                    target.html(vhrNotFoundHOFoundHtml);

                    target.html(target.html().replace(/{HO}/g, foundHeritage(payload.overlays).zoneCode));
                    target.html(target.html().replace(/{address}/g, unstructuredAddress));
                    target.html(target.html().replace(/{lgaName}/g, lgaName));
                    target.html(target.html().replace(/{lgaLink}/g, "https://knowyourcouncil.vic.gov.au/councils/" + lgaName.replace(/\s+/g, '-').toLowerCase()));

                    target.show();
                }
              
                if (!vhrNumber && !foundHeritage(payload.overlays)) {
                    // hide other
                    $('.outcome-condition').hide(); 

                    var target = $('#vhrNotFoundHONotFound');

                    //set it back to original
                    target.html(vhrNotFoundHONotFoundHtml);
                    
                    target.html(target.html().replace(/{address}/g, unstructuredAddress));
                    $('#vhrNotFoundHONotFound').show();
                }

            } 
        }, false)

    }

    function foundHeritage(overlays) {
        console.log(overlays)
        if (!overlays) {
          return false
        }
      
        return overlays.find(overlay => overlay.zoneGroupLabel === 'HO - Heritage Overlay');
    }


}(jQuery));
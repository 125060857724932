(function() {
    'use strict';
    const socialSharing = {
        settings: {
            wrapper:   'title-banner__heading-wrapper',
            banner:    'title-banner',
            pageShare: 'data-share',
            facebook:  'fb',
            twitter:   'tw',
            linkedin:  'li',
            email:     'email'
        },
        init: () => {
            const _this = socialSharing;
            if (document.querySelector(`.${_this.settings.wrapper}`)) {
                const share = document.querySelector(`.${_this.settings.banner}`).getAttribute('data-share');
            
                if (share === 'yes') {
                    _this.addthisAPI(); 
                    _this.createContener();
                }

            }
        },
        addthisAPI: () => {
            const addthisScript = document.createElement('script');
            const firstScriptTag = document.getElementsByTagName('script')[0];

            addthisScript.src = '//s7.addthis.com/js/300/addthis_widget.js#pubid=ra-58801cf109d458af';
            firstScriptTag.parentNode.insertBefore(addthisScript, firstScriptTag);
        },
        createContener: () => {
            const _this = socialSharing;
            const contener = document.createElement('div');
            contener.className = 'title-banner__social-sharing';
            contener.innerHTML = `
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-xs-12 col-sm-12 col-md-10 col-lg-10">
                            <span class="title-banner__social-sharing__text">Share this page</span>
                              ${_this.createSocialLink()}
                        </div>
                    </div>
                </div>
            `;
            document.querySelector(`.${_this.settings.banner}`).appendChild(contener);
        },
        createSocialLink: () => {
            const _this = socialSharing;
            const shareOptions = document.querySelector(`.${_this.settings.banner}`).getAttribute('data-share-option');
            let socialLinks = '';

            if (shareOptions.indexOf(`${_this.settings.facebook}`) !== -1) {
                socialLinks += `<a class="addthis_button_facebook title-banner__social-sharing__item">
                    <span class="title-banner__social-sharing__item--ico title-banner__social-sharing__item--facebook"></span>
                  </a>`;
            } 
            if (shareOptions.indexOf(`${_this.settings.twitter}`) !== -1) {
                socialLinks += `<a class="addthis_button_twitter title-banner__social-sharing__item">
                    <span class="title-banner__social-sharing__item--ico title-banner__social-sharing__item--twitter"></span>
                  </a>`;
            } 
            if (shareOptions.indexOf(`${_this.settings.linkedin}`) !== -1) {
                socialLinks += `<a class="addthis_button_linkedin title-banner__social-sharing__item">
                    <span class="title-banner__social-sharing__item--ico title-banner__social-sharing__item--linkedin"></span>
                  </a>`;
            } 
            if (shareOptions.indexOf(`${_this.settings.email}`) !== -1) {
                socialLinks += `<a class="addthis_button_email title-banner__social-sharing__item">
                    <span class="title-banner__social-sharing__item--ico title-banner__social-sharing__item--email"></span>
                </a>`;
            } 

            return socialLinks;
        }
    };


    socialSharing.init();

}());

(function($){
    'use strict';

    $(document).ready(function() {
        $(".media-link__title").each(function(){
            var _self = $(this);
    
            if (_self.outerHeight() > 33){
                _self.addClass('media-link__title_two');
            } else {
                _self.removeClass('media-link__title_two');
            }
        });
    });
    
    $(window).resize(function() {
        $(".media-link__title").each(function(){
            var _self = $(this);
    
            if (_self.outerHeight() > 33){
                _self.addClass('media-link__title_two');
            } else {
                _self.removeClass('media-link__title_two');
            }
        });
    });
    
}(jQuery));
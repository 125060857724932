/*global jQuery*/
(function ($) {
    'use strict';

    var $socialShowcase = $('#social-showcase .row');
    
    if ($socialShowcase.length) {
        $socialShowcase.masonry({
            itemSelector:    '.col-xs-12',
            percentPosition: true
        });
    }

}(jQuery));
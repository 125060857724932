/*global jQuery, visible*/
var accordions = {};

(function ($) {
    'use strict';

    accordions.init = function (drawer) {
        var $accordion = (drawer) ? $('#content-drawer .accordion') : $('.accordion'),
            $tabs      = (drawer) ? $('#content-drawer .tabs') : $('.tabs'),
            $tabsList  = (drawer) ? $('#content-drawer .tabs__tab-list') : $('.tabs__tab-list'),
            $slick,
            slideCount;

        $accordion.UberAccordion({
            buttonClass:          'accordion__link',
            autoExpand:           null,
            toggle:               true,
            multiple:             true,
            expandOnFocus:        false,
            preventDefaultButton: true,
            hashChange:           true
        });

        $tabs.UberAccordion({
            buttonClass: 'tabs__link',

            autoExpand: function ($elem) {
                return window.location.hash || this.$buttons.first().attr('href');
            },

            toggle:               false,
            multiple:             false,
            preventDefaultButton: true
        });

        if ($tabs.length) {

            $tabsList
                .on('afterChange', function (event, slick, currentSlide) {

                    // Hide $prevArrow when no LHS slides to show
                    if (currentSlide === 0) {
                        slick.$prevArrow.hide();
                    } else {
                        slick.$prevArrow.show();
                    }

                    // Hide $nextArrow when last slide is visible
                    if (visible(slick.$slides[slick.slideCount - 1])) {
                        slick.$nextArrow.hide();
                    } else {
                        slick.$nextArrow.show();
                    }
                });

            if ($tabsList.length) {

                $tabsList.slick({
                    accessibility:  false,
                    slidesToScroll: 1,
                    dots:           false,
                    focusOnSelect:  false,
                    centerMode:     false,
                    infinite:       false,
                    touchMove:      true,
                    variableWidth:  true
                });

                $tabsList.each(function () {
                    $slick     = $(this).slick('getSlick');
                    slideCount = $slick.slideCount;

                    // Hide prev arrow on init
                    $slick.$prevArrow.hide();

                    // Hide $nextArrow when last slide is visible
                    if (visible($slick.$slides[$slick.slideCount - 1])) {
                        $slick.$nextArrow.hide();
                    } else {
                        $slick.$nextArrow.show();
                    }
                });
            }
        }
    };

    accordions.openCurrent = function() {
        if (window.location.hash.indexOf('accordion__target-') !== -1) {
            var currentAccordionBtn = $('a[href="'+window.location.hash+'"]');
            var currentAccordionContent = $(window.location.hash);
            if (!currentAccordionContent.hasClass('uber-accordion__target-active')) {
                currentAccordionBtn.click();
                accordions.scrollTo(currentAccordionBtn);
            }
        }
    };

    accordions.scrollTo = function(accordionBtn) {
        $(window).on('load', function(){
            if (accordionBtn.length) {
                $('html, body').animate({
                    scrollTop: accordionBtn.offset().top
                }, 500);
            }
        });
    }

    // Document ready
    $(document).ready(function () {

        // Run init
        accordions.init();

        // Open current accordion
        accordions.openCurrent();

    });
}(jQuery));